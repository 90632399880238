document.addEventListener('DOMContentLoaded', function() {
    let navi = document.getElementById('navigation');
    if (navi) {
        let path = window.location.pathname;
        let fullPath = window.location.hostname + path;
        loop: while (fullPath !== window.location.hostname) {
            for (a of navi.getElementsByTagName('a')) {
                if (a.classList.contains('dropdown-toggle')) {
                    continue;
                }
                let href = a.getAttribute('href').replace(/(^\w+:|^)\/\/|\/+$/, '');
                if (href === path || href === fullPath) {
                    a.classList.add('active');
                    let parent = a.parentNode;
                    while (parent !== document.body) {
                        if (parent.tagName = 'ul' && parent.classList.contains('collapse')) {
                            parent.classList.add('in');
                            for (let t of document.getElementsByClassName('dropdown-toggle')) {
                                if (t.getAttribute('href') === '#' + parent.getAttribute('id')) {
                                    t.setAttribute('aria-expanded', 'true');
                                    t.classList.remove('collapsed');
                                }
                            }
                        }
                        parent = parent.parentNode;
                    }
                    break loop;
                }
            }
            fullPath = fullPath.replace(/\/[^\/]+\/?$|\/$/, '');
            path = path.replace(/\/[^\/]+\/?$|\/$/, '');
        }

        /*
        while (fullPath !== window.location.hostname && !match) {
            for (let ul of navi.querySelectorAll('ul.collapse')) {
                if (match) {
                    return;
                }
                for (let a of ul.getElementsByTagName('a')) {
                    if (match) {
                        return;
                    }
                    // remove protocol and trailing slashes
                    let href = (a.getAttribute('href') + "//").replace(/((^\w+:|^)\/\/|\/+$)/g, '');
                    if (href === path || href === fullPath) {
                        a.classList.add('active');
                        ul.classList.add('in');
                        ul.setAttribute('aria-expanded', 'true');
                        match = true;
                    }
                }
            }
            fullPath = fullPath.replace(/\/[^\/]+\/?$|\/$/, '');
        }
        */
    }

    let overlay = document.getElementById('overlay');
    if (overlay) {
        document.addEventListener('keyup', function (e) {
            let overlay = document.getElementById('overlay');
            if (overlay && e.keyCode === 27 && overlay.classList.contains('active')) {
                hideOverlay();
            }
        });
        overlay.addEventListener('click', function (e) {
            if (e.target === overlay && overlay.classList.contains('active')) {
                hideOverlay();
            }
        });
    }

    let container = document.getElementById('blur');
    if (container) {
        container.addEventListener('resize', function () {
            let groupElements = document.getElementsByClassName('grouping');
            let groupings = {};
            for (let i = 0; i < groupElements.length; i++) {
                for (let c = 0; c < groupElements[i].classList.length; c++) {
                    let cssClass = groupElements[i].classList[c];
                    if (cssClass.match(/^group[0-9]+$/)) {
                        if (!groupings.hasOwnProperty(cssClass)) {
                            groupings[cssClass] = 0;
                        }
                        if (groupings[cssClass] < groupElements[i].offsetHeight) {
                            groupings[cssClass] = groupElements[i].offsetHeight;
                        }
                    }
                }
            }
            for (let cssClass in groupings) {
                let elements = document.getElementsByClassName(cssClass);
                for (let e = 0; e < elements.length; e++) {
                    elements[e].style.minHeight = groupings[cssClass] + 'px';
                }
            }
            let nav = document.getElementById('navigation');
            if (nav !== undefined) {
                resizeMenu(nav);
            }
        });
        let event = new Event('resize');
        container.dispatchEvent(event);
    }
});

function showOverlay()
{
    let blur = document.getElementById('blur');
    if (blur) {
        blur.classList.add('active');
    }
    let ol = document.getElementById('overlay');
    if (ol) {
        ol.classList.add('active');
    }
}

function hideOverlay()
{
    let blur = document.getElementById('blur');
    if (blur) {
        blur.classList.remove('active');
    }
    let ol = document.getElementById('overlay')
    if (ol) {
        ol.classList.remove('active');
    }
}

function resizeMenu(nav)
{
    let height = document.body.scrollHeight,
        header = document.getElementById('header');
    if (header !== undefined) {
        height -= header.clientHeight;
    }
    nav.style.minHeight = `${height}px`;
}

function toggleMenu()
{
    let nav = document.getElementById('navigation');
    if (nav === undefined) {
        return
    }
    resizeMenu(nav);
    document.getElementById('navigation').classList.toggle('active');
}